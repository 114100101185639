import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { BrowserView, MobileView } from 'react-device-detect'
import Link from '../components/CustomLink'
import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Content, { HTMLContent } from '../components/Content'
import TagItem from '../components/TagItem'
import PostInfo from '../components/PostInfo'
import { ArrowBackIcon, ArrowForwardIcon } from '../img/icons'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  tags,
  title,
  date,
  readingTime,
  helmet,
  featuredpost,
  featuredimage,
  prev,
  next,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="p-6 md:p-4 md:pt-8">
      {helmet || ''}
      <BrowserView>
        <Link to="/blog" className="pt-4 block">
          <ArrowBackIcon />
          <span className="ml-1">Back to blog</span>
        </Link>
      </BrowserView>
      <article className={`${featuredpost ? 'is-featured' : ''}`}>
        <header className="pt-4">
          <h1 className="text-3xl font-medium leading-tight">{title}</h1>
          <PostInfo date={date} readingTime={readingTime} className="mt-1" />
          {/* image */}
          {featuredimage ? (
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredimage,
                alt: `Featuredimage for post ${title}`,
              }}
              className="mt-3 md:mt-6"
            />
          ) : null}
        </header>
        <div className="mt-3 md:mt-6">
          <PostContent content={content} />
          <div className="text-left mt-3">
            {tags && tags.length ? (
              <ul className="flex flex-wrap mt-6 md:mt-2">
                {tags.map(tag => (
                  <li key={tag + `tag`}>
                    <Link to={`/tags/${kebabCase(tag)}/`}>
                      <TagItem text={tag} />
                    </Link>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
        {(prev || next) && (
          <div className="flex justify-between mt-8 md:mt-10">
            <div className="flex-1 text-left md:max-w-xs md:max-w-sm pr-2">
              {prev && (
                <>
                  <BrowserView>
                    <div className="text-xs text-medium-on-surface-0 ml-1 pl-6">Previous</div>
                    <Link to={prev.fields.slug} className="text-xs">
                      <ArrowBackIcon />
                      <span className="ml-1">{prev.frontmatter.title}</span>
                    </Link>
                  </BrowserView>
                  <MobileView>
                    <Link to={prev.fields.slug} className="text-xs">
                      <ArrowBackIcon />
                      <span className="ml-1">Previous post</span>
                    </Link>
                  </MobileView>
                </>
              )}
            </div>

            <div className="flex-1 text-right md:max-w-xs md:max-w-sm pl-2">
              {next && (
                <>
                  <BrowserView>
                    <div className="text-xs text-medium-on-surface-0 mr-1 pr-6">Next post</div>
                    <Link to={next.fields.slug} className="text-xs">
                      <span className="mr-1">{next.frontmatter.title}</span>
                      <ArrowForwardIcon />
                    </Link>
                  </BrowserView>
                  <MobileView>
                    <Link to={next.fields.slug} className="text-xs">
                      <span className="mr-1">Next</span>
                      <ArrowForwardIcon />
                    </Link>
                  </MobileView>
                </>
              )}
            </div>
          </div>
        )}
      </article>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  tags: PropTypes.array,
  title: PropTypes.string,
  date: PropTypes.string,
  helmet: PropTypes.object,
  featuredpost: PropTypes.bool,
  featuredimage: PropTypes.object,
  readingTime: PropTypes.object,
  prev: PropTypes.object,
  next: PropTypes.object,
}

const BlogPost = ({ data, pageContext }) => {
  const { markdownRemark: post } = data
  const { next, prev } = pageContext

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        date={post.frontmatter.date}
        readingTime={post.fields.readingTime}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            {post.frontmatter.og_description && (
              <meta name="description" content={`${post.frontmatter.og_description}`} />
            )}
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.featuredimage}
        prev={prev}
        next={next}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        readingTime {
          text
        }
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        featuredpost
        title
        og_description
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 450, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tags
      }
    }
  }
`
